<script setup lang="ts">
interface Props {
  options: {
    root?: HTMLElement
    rootMargin?: string
    threshold?: number
  }
}

interface State {
  observer: IntersectionObserver | undefined
}

const props = withDefaults(defineProps<Props>(), {
  options: () => ({}),
})

const emit = defineEmits(['intersect'])

const observed = ref<HTMLElement>()
const options = toRef(props, 'options')

const state: State = reactive({
  observer: undefined,
})

const positionTop = computed(() =>
  options.value !== undefined && options.value.threshold !== undefined
    ? `${options.value.threshold * 100}%`
    : '100%',
)

onMounted(() => {
  if (!observed.value)
    return

  state.observer = new IntersectionObserver(
    ([entry]) => {
      if (entry && entry.isIntersecting)
        emit('intersect')
    },
    {
      // BUGFIX: See commit
      // @ts-expect-error - IntersectionObserverInit is missing the delay property
      delay: 100,
      trackVisibility: true,
      ...options.value,
    },
  )

  state.observer?.observe(observed.value)
})

onUpdated(() => {
  if (!observed.value)
    return
  state.observer?.observe(observed.value)
})

onUnmounted(() => {
  state.observer?.disconnect()
})
</script>

<template>
  <div
    ref="observed"
    class="observer"
    :style="{ '--position-top': positionTop }"
  />
</template>

<style scoped>
.observer {
  height: 1px;
  left: 0;
  right: 0;
  top: var(--position-top);
  z-index: -1;
  position: absolute;
}
</style>
